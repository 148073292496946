<template>
    <div class="container" id="app">
        <div class="row">
            <div class="col-12 col-sm-6 mt-2">
                <input type="text" v-model="list_text" class="form-control"
                       placeholder="Input your text and random it.">
            </div>
            <div class="col-6 col-sm-2 mt-2">
                <select class="form-control " v-model="split_text">
                    <option value="">Each character</option>
                    <option value=",">,</option>
                </select>
            </div>
            <div class="col-6 col-sm-2 mt-2">
                <select class="form-control " v-model="voice_speed">
                    <option value="">Voice speed</option>
                    <option value="0.5">0.5</option>
                    <option value="0.75">0.75</option>
                    <option value="1">1</option>
                </select>
            </div>
            <div class="col-12 col-sm-2 mt-2">
                <button class="form-control btn btn-primary" @click="randomText">Random Text</button>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <p class="text-center font-yumincho-msmin-hiramin-pron-and-w6"
                   :style="text_to_show.length > 1?'font-size: 180px':'font-size: 350px'"
                   @click="speechText">
                    {{ text_to_show }}
                </p>
                <audio v-show="false" ref="audioPlayer" controls></audio>
            </div>
        </div>
    </div>
</template>
<script>

import speechAPI from './speech'

export default {
    data() {
        return {
            split_text        : ',',
            voice_speed       : 0.75,
            single_hira_text  : 'あ,い,う,え,お,か,き,く,け,こ,さ,し,す,せ,そ,た,ち,つ,て,と,な,に,ぬ,ね,の,は,ひ,ふ,へ,ほ,ま,み,む,め,も,や,ゆ,よ,ら,り,る,れ,ろ,わ,を,ん,が,ぎ,ぐ,げ,ご,ざ,じ,ず,ぜ,ぞ,だ,ぢ,づ,で,ど,ば,び,ぶ,べ,ぼ,ぱ,ぴ,ぷ,ぺ,ぽ',
            single_kata_text  : 'ア,イ,ウ,エ,オ,カ,キ,ク,ケ,コ,サ,シ,ス,セ,ソ,タ,チ,ツ,テ,ト,ナ,ニ,ヌ,ネ,ノ,ハ,ヒ,フ,ヘ,ホ,マ,ミ,ム,メ,モ,ヤ,ユ,ヨ,ラ,リ,ル,レ,ロ,ワ,ヲ,ン',
            compound_hira_text: 'しゃ,しゅ,しょ,ちゃ,ちゅ,ちょ,にゃ,にゅ,にょ,ひゃ,ひゅ,ひょ,みゃ,みゅ,みょ,りゃ,りゅ,りょ,ぎゃ,ぎゅ,ぎょ,じゃ,じゅ,じょ,びゃ,びゅ,びょ,ぴや,ぴゅ,ぴょ',
            list_text         : '',
            audioContent      : '',
            text_to_show      : '',
            audio             : null
        }
    },
    mounted() {
        this.list_text = this.single_hira_text + ',' + this.compound_hira_text + ',' + this.single_kata_text
        this.randomText();
        this.audio = new Audio()
    },
    methods: {
        randomText() {
            this.audioContent = ''
            let list_text     = this.list_text.split(this.split_text);
            let random        = Math.floor(Math.random() * list_text.length);
            this.text_to_show = list_text[random];
            speechAPI.generateSpeech(this.text_to_show,this.voice_speed)
                .then(audioContent => {
                    this.audioContent = audioContent
                    this.audio.src    = `data:audio/mp3;base64,${this.audioContent}`
                })
        },
        async speechText() {
            const audioPlayer = this.$refs.audioPlayer
            if (audioPlayer && this.audioContent) {
                audioPlayer.src = this.audio.src
                await audioPlayer.play()
            }
        },
    }
}
</script>

<style>
.font-yumincho-msmin-hiramin-pron-and-w6 {
    font-family: HiraMinProN-W6, "Hiragino Mincho ProN", "游明朝体", "Yu Mincho", YuMincho, "MS Mincho", serif;
}
</style>
